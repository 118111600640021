import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import Icon from './Icon.js';
import { styles } from 'ansi-colors';

const useStyles = makeStyles(theme => ({
    commentPlaceholder: {
        height: '40px',
        paddingTop: '8px',
        textAlign: 'center',
    },
    comments: {
        display: 'flex',
        flexDirection: 'column',
    },
    avatar: {},
    actionBar: {
        marginTop: '0',
        display: 'flex',
        justifyContent: 'space-between',
    },
    commentInput: {
        marginTop: '0',
        marginLeft: '5px',
        width: '100%',
    },
    postButton: {
        margin: '0 5px',
    },
}));

const getInitials = (userName) => {
    let initials = userName.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

    return initials;
}

function hashCode(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
       hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
} 

function intToRGB(i){
    var c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
}

const getColor = (userName) => {
    const hash = hashCode(userName);
    return `#${intToRGB(hash)}`;
}

export default function Comments(props) {
  const [commentInputValue, setCommentInputValue] = useState('');
  const [isPosting, setIsPosting] = useState(false);

  const classes = useStyles();

  const handlePostComment = (comment) => {
    setIsPosting(true);
    props.postComment(comment);
  }

    const comments = [];
    if (!props.comments) {
        comments.push(
            <CardContent >
                <div className={classes.commentPlaceholder}>
                    <i>Be the first to post a comment!</i>
                </div>
            </CardContent>
        )
    } else {
        props.comments.forEach(comment => (
            comments.push(
                <CardHeader
                    key={comment.sys.id}
                    avatar={
                        <Avatar 
                            aria-label="recipe"
                            className={classes.avatar}
                            style={{
                                'backgroundColor': getColor(comment.fields.userName),
                            }}
                        >
                            {getInitials(comment.fields.userName)}
                        </Avatar>
                    }
                    title={comment.fields.text}
                    subheader={new Date(comment.sys.createdAt).toLocaleDateString()}
                />
            )
        ));
    }

    if (isPosting) {
        if (!props.comments) comments.pop();

        comments.push(
            <CardContent>
                <div className={classes.commentPlaceholder}>
                    <i>Posting your comment ...</i>
                </div>
            </CardContent>
        );
    }
  
    return (
      <Card className={classes.comments}>
        {comments}
        <div 
            className={classes.actionBar}
        >
            <TextField
                id="outlined-name"
                label="Add a comment"
                className={classes.commentInput}
                value={commentInputValue}
                disabled={isPosting}
                onChange={e => setCommentInputValue(e.target.value)}
                margin="normal"
                variant="outlined"
            />
            <CardActions
                className={classes.postButton}
                disableSpacing
            >
                <IconButton
                    aria-label="comment"
                    disabled={!commentInputValue.trim() || isPosting}
                    onClick={() => handlePostComment(commentInputValue)}
                >
                    <Icon name='Comment' />
                </IconButton>
            </CardActions>
        </div>
      </Card>
    );
  }
