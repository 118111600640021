import React, { useRef } from 'react';

import NavTop from '../NavTop.js';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import optionImage from '../img/create-image.svg';
import optionVideo from '../img/create-video.svg';
import optionPost from '../img/create-post.svg';
import optionLink from '../img/create-link.svg';
import pollLink from '../img/create-poll.svg';

const useStyles = makeStyles(theme => ({
  root: {
  },
  cardOption: {
    padding: '8px',
    boxShadow: '0px 10px 20px rgba(0,0,0,0.2)'
  },
  cardOptionImg: {
    height: '120px',
    width: '80%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    margin: '0 auto 4px auto'
  },
  cardOptionText: {
    textAlign: 'center',
    display: 'block',
    fontWeight: 500
  },
  hideInput: {
    display: 'none'
  }
}));

export default function WhatUMaking({
  setContentType,
  setImageFile,
  setVideoFile,
  setImageBuffer,
  setVideoBuffer,
  stopCreating
}) {
  const imageUpload = useRef(null);
  const videoUpload = useRef(null);

  const classes = useStyles();

  function saveImage() {
    if (imageUpload && imageUpload.current && imageUpload.current.files && imageUpload.current.files[0]) {
      const imageFile = imageUpload.current.files[0];
      setImageFile(imageFile);
      setArrayBuffer(imageFile, setImageBuffer, 'image');
    }
  }

  function saveVideo() {
    if (videoUpload && videoUpload.current && videoUpload.current.files && videoUpload.current.files[0]) {
      const videoFile = videoUpload.current.files[0];
      setVideoFile(videoFile);
      setArrayBuffer(videoFile, setVideoBuffer, 'video');
    }
  }

  function setArrayBuffer(file, setFunc, contentType) {
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      const content = e.target.result;

      setFunc(content);
      setContentType(contentType);
    }
    fileReader.readAsArrayBuffer(file)
  }

  return (

    <div>
      <NavTop
        title='Add new content'
        buttonLeft='close'
        stopCreating={stopCreating}
      />
      <Container>
      <Grid container spacing={3}>

        <Grid item xs={6}>
          <Paper className={classes.cardOption}>
            <label>
            <div 
              className={classes.cardOptionImg} 
              style={{backgroundImage: `url(${optionImage})`}}></div>
            <input
        className={classes.hideInput}
        type="file"
        accept="image/*"
        capture
        ref={imageUpload}
        onChange={() => saveImage()}
          />
          <div className={classes.cardOptionText}>Add an Image</div>
          </label>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper className={classes.cardOption}>
            <label>
            <div 
              className={classes.cardOptionImg} 
              style={{backgroundImage: `url(${optionVideo})`}}></div>
            <input
          className={classes.hideInput}
          type="file"
          accept="video/*"
          capture
          ref={videoUpload}
          onChange={() => saveVideo()}
        />
          <div className={classes.cardOptionText}>Add a Video</div>
          </label>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper className={classes.cardOption} onClick={() => setContentType('text')}>
            <label>
            <div 
              className={classes.cardOptionImg} 
              style={{backgroundImage: `url(${optionPost})`}}></div>
          <div className={classes.cardOptionText}>Write a Post</div>
          </label>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper className={classes.cardOption} onClick={() => setContentType('text')}>
            <label>
            <div 
              className={classes.cardOptionImg} 
              style={{backgroundImage: `url(${optionLink})`}}></div>
          <div className={classes.cardOptionText}>Add a Link</div>
          </label>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper className={classes.cardOption} onClick={() => {}}>
            <label>
            <div 
              className={classes.cardOptionImg} 
              style={{backgroundImage: `url(${pollLink})`}}></div>
          <div className={classes.cardOptionText}>Create a Poll</div>
          </label>
          </Paper>
        </Grid>
      </Grid>
      </Container>
    </div>
  );
}