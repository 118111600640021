import Button from '@material-ui/core/Button';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';

import { createImageCard, createTextCard, createVideoCard } from '../data-access';
import Icon from '../Icon.js';
import NavTop from '../NavTop.js';

// Ripped from stack overflow.
function _arrayBufferToBase64( buffer ) {
  var binary = '';
  var bytes = new Uint8Array( buffer );
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
  }
  return window.btoa( binary );
}

// These are just copied from the DeckList.
const useStyles = makeStyles(theme => ({
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    scrollSnapType: 'x proximity',
    margin: '8px 0 0 0',
  },
  deckItem: {
    scrollSnapAlign: 'start',
    cursor: 'pointer',
  },
  deckCover: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    position: 'relative',

    '&:after': {
      content: '""',
      display: 'block',
      background: 'rgba(0, 0, 0, 0.5)',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      position: 'absolute',
      borderRadius: theme.shape.borderRadius,
      opacity: 0,
      transition: 'opacity 0.2s'
    },

    // '&:hover:after': {
    //   opacity: 0.8,
    // },
  },
  deckIcon: {
    width: 'auto',
    height: 'auto',
    flex: 1,
    opacity: 0.75,
    padding: '20%',
  },
  deckName: {
    textDecoration: 'none',
    fontSize: '18px',
    fontWeight: 500,
    padding: '8px 0',

    // '&:hover': {
    //   textDecoration: 'underline',
    // },
  },
  inputFull: {
    display: 'block'
  },
  checkBadge: {
    color: 'white',
    position: 'absolute',
    zIndex: '2',
    width: '40px',
    top: '4px',
  },
  buttonSheet: {
    backgroundColor: 'white',
    height: '60px',
    position: 'fixed',
    bottom: '80px',
    right: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
  },
  addCardButton: {
    boxShadow: 'none',
    marginTop: '16px'
  }
}));

const PlaceHolder = ({
  contentType,
  imageFile,
  imageBuffer,
  videoFile,
  videoBuffer,
}) => {
  let contentPlaceholder = null;
  if (contentType === 'image') {
    // Very hacky.
    contentPlaceholder =
      <img
        src={`data:${imageFile.type};base64,${_arrayBufferToBase64(imageBuffer)}`}
        alt="Content"
        style={{
          width: '100%',
          borderRadius: '4px',
          maxHeight: '180px',
          objectFit: 'contain'
        }}
      />;
  } else if (contentType === 'video') {
    contentPlaceholder =
      <video
        src={`data:${videoFile.type};base64,${_arrayBufferToBase64(videoBuffer)}`}
        alt="Video Content"
        style={{
          width: '100%',
          borderRadius: '4px',
          maxHeight: '180px',
          objectFit: 'contain'
        }}
        controls
      />;
  }

  return contentPlaceholder;
};

export default function MakeDat({
  decks,
  contentType,
  textMessage,
  imageFile,
  videoFile,
  imageBuffer,
  videoBuffer,
  setShouldFetch,
  stopCreating,
}) {
  // Messages are used for text cards.
  const [message, setMessage] = useState('');

  const [selectedDeckId, setSelectedDeckId] = useState(null);
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  // Get the upload button.
  let isButtonDisabled;
  let navTitle = 'Create';
  const buttonText = isLoading ? 'Uploading...' : 'Add to Team Library';
  if (contentType === 'image') {
    isButtonDisabled = !description || !imageFile || !selectedDeckId || isLoading;
    navTitle = 'Add an Image';
  } else if (contentType === 'video') {
    isButtonDisabled = !description || !videoFile || !selectedDeckId || isLoading;
    navTitle = 'Add a Video';
  } else if (contentType === 'text') {
    isButtonDisabled = !description || !message || !selectedDeckId || isLoading;
    navTitle = 'Compose a post';
  }

  function uploadContent() {
    setIsLoading(true);

    if (contentType === 'image') {
      createImageCard(
        selectedDeckId,
        description,
        imageFile.name,
        imageFile.type,
        imageBuffer
      ).then(() => {
        setShouldFetch(true)
        stopCreating()
      });
    } else if (contentType === 'video') {
      createVideoCard(
        selectedDeckId,
        description,
        videoFile.name,
        videoFile.type,
        videoBuffer
      ).then(() => {
        setShouldFetch(true)
        stopCreating()
      });
    } else if (contentType === 'text') {
      createTextCard(selectedDeckId, description, message)
      .then(() => {
        setShouldFetch(true);
        stopCreating();
      });
    }
  }

  return (
    <div className="make-dat">
      {
        isLoading && (
          <>
            <div className="loading-overlay" />
            <div className="progress-spinner">
              <CircularProgress
                color="primary"
                style={{ color: '#3f51b5' }}
              />
            </div>
          </>
        )
      }
      <NavTop
        title={navTitle}
        buttonLeft='close'
        stopCreating={stopCreating}
      />
      <Container>

        <PlaceHolder
          className={classes.placeholder}
          contentType={contentType}
          imageFile={imageFile}
          imageBuffer={imageBuffer}
          videoFile={videoFile}
          videoBuffer={videoBuffer}
        />

        <div>
          {
            contentType === 'text' && (
              <TextField
                fullWidth
                className={classes.inputFull}
                label="Title"
                value={message}
                onChange={evt => setMessage(evt.target.value)}
                margin="normal"
                variant="outlined"
              />
            )
          }
          <TextField
            fullWidth
            multiline
            rows="2"
            label="Description"
            value={description}
            onChange={evt => setDescription(evt.target.value)}
            margin="normal"
            variant="outlined"
          />
        </div>

      <div>
    
      </div>

      <div>
        <div className="select-a-deck">Select a deck:</div>

        <GridList
          cols={2.5}
          className={classes.gridList}
          spacing={15}
        >
          {decks.map(deck => (
            <GridListTile
              key={deck.sys.id}
              className={classes.deckItem}
              onClick={() => setSelectedDeckId(deck.sys.id)}
              style={{position: 'relative', overflow: 'visible'}}
            >
              {deck.sys.id === selectedDeckId && (<Icon name='CheckCircle' className={classes.checkBadge}/>)}
              <div
                className={classes.deckCover}
                style={{ backgroundColor: deck.fields.coverColor }}
               >
                <Icon
                  name={deck.fields.coverIcon}
                  className={classes.deckIcon}
                  style={{ color: '#fff' }}
                />
              </div>
              <div className={classes.deckName}>{deck.fields.name}</div>
          </GridListTile>
        ))}
        </GridList>
      </div>
       
        <Button className={classes.addCardButton} fullWidth variant="contained" color="primary" onClick={uploadContent} disabled={isButtonDisabled}>
          {buttonText}
        </Button>
      </Container>
    </div>
  );
}
