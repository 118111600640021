import { createClient } from 'contentful-management';

const EN_US = 'en-US';
const SPACE_ID = 'kqtvjeiyddqx';
const DECK_CONTENT_TYPE = 'deck';
const CARD_CONTENT_TYPE = 'card';
const COMMENT_CONTENT_TYPE = 'comment';
const TEXT_CARD_TYPE = 'text';
const IMAGE_CARD_TYPE = 'image';
const VIDEO_CARD_TYPE = 'video';

const client = createClient({
  // This is Frank's access token lol.
  accessToken: 'CFPAT-XBtQeVyWdgfZmhaQfoD3EmR5mhiYWh1wDd29EpKY50o'
});

async function getSpace() {
  const space = await client.getSpace(SPACE_ID);
  return space;
}

export async function getEntries() {
  const space = await getSpace();
  const entries = await space.getEntries();
  console.log(entries.items);
  return entries;
}

export async function getDecks() {
 const space = await getSpace();
 const decks = await space.getEntries({ content_type: DECK_CONTENT_TYPE });
 console.log(decks.items);
 return decks.items;
}

// Grabs an entry (Deck, Card) by ID.
export async function getEntry(entryId) {
  const space = await getSpace();
  const entry = await space.getEntry(entryId);
  return entry;
 }

export async function getCards() {
  const space = await getSpace();
  const cards = await space.getEntries({ content_type: CARD_CONTENT_TYPE });
  console.log(cards.items);
  return cards.items;
 }

 // Creates a new Entry (Card, Deck).
 // ContentTypeId is one of 'deck', 'card'.
 export async function createEntry(contentTypeId, data) {
  const space = await getSpace();
  const newEntry = await space.createEntry(contentTypeId, data);
  return newEntry;
 }

 // Creates a text card in a deck and returns the created card.
 export async function createTextCard(deckId, description, textContent) {
  // Get the relevant deck, needs to be done through the management API.
  const deck = await getEntry(deckId);

  const data = {
    fields: {
      desc: {
        [EN_US]: description,
      },
      type: {
        [EN_US]: TEXT_CARD_TYPE,
      },
      rating: {
        [EN_US]: 0,
      },
      textContent: {
        [EN_US]: textContent,
      },
    }
  };
  const createdCard = await createEntry(CARD_CONTENT_TYPE, data);
  
  // Publish the card.
  createdCard.publish();

  // Associate the card with the deck.
  deck.fields.cards[EN_US] = [...deck.fields.cards[EN_US], {
    sys: {
      type: 'Link',
      linkType: 'Entry',
      id: createdCard.sys.id,
    }
  }];
  
  const updatedDeck = await deck.update();
  // Publish the change.
  await updatedDeck.publish();

  return createdCard;
 }

const createAsset = async (fileName, fileType, fileContent) => {
  const space = await getSpace();
  // Create the asset.
  const assetData = {
    fields: {
      file: {
        [EN_US]: {
          contentType: fileType,
          fileName,
          file: fileContent,
        }
      }
    }
  };
  const asset = await space.createAssetFromFiles(assetData);
  // Process and publish the asset.
  const processedAsset = await asset.processForAllLocales()
  const publishedAsset = await processedAsset.publish();
  return publishedAsset;
};

 async function createAssetCard(cardType, deckId, description, fileName, fileType, fileContent) {
  // Get the relevant deck, needs to be done through the management API.
  const deck = await getEntry(deckId);
  const asset = await createAsset(fileName, fileType, fileContent);

  const payload = cardType === VIDEO_CARD_TYPE ? {
    video: {
      [EN_US]: {
        sys: {
          type: 'Link',
          linkType: 'Asset',
          id: asset.sys.id,
        }
      }
    }
  } : {
    images: {
      [EN_US]: [
        {
          sys: {
            type: 'Link',
            linkType: 'Asset',
            id: asset.sys.id,
          }
        }
      ]
    }
  };
  const data = {
    fields: {
      desc: {
        [EN_US]: description,
      },
      type: {
        [EN_US]: cardType,
      },
      rating: {
        [EN_US]: 0,
      },
      ...payload,
    }
  };

  const createdCard = await createEntry(CARD_CONTENT_TYPE, data);
  createdCard.publish();
   
  // Associate the card with the deck.
  deck.fields.cards[EN_US] = [...deck.fields.cards[EN_US], {
    sys: {
      type: 'Link',
      linkType: 'Entry',
      id: createdCard.sys.id,
    }
  }];
  
  const updatedDeck = await deck.update();
  // Publish the change.
  await updatedDeck.publish();

  return createdCard;
 }

export async function createImageCard(deckId, description, fileName, fileType, imageContent) {
  const cardType = IMAGE_CARD_TYPE;
  await createAssetCard(cardType, deckId, description, fileName, fileType, imageContent);
}

export async function createVideoCard(deckId, description, fileName, fileType, imageContent) {
  const cardType = VIDEO_CARD_TYPE;
  await createAssetCard(cardType, deckId, description, fileName, fileType, imageContent);
}

const USERS = ['Franklin Doe', 'Jonathan Blow', 'Alexander Bradley', 'Zane Xavier', 'Spring Lamb'];
const getRandomeUser = () => {
  return USERS[Math.floor(Math.random() * USERS.length)];
}
export async function postComment(cardId, text, userName) {
  // Get the relevant deck, needs to be done through the management API.
  const card = await getEntry(cardId);

  const user = getRandomeUser();

  const data = {
      fields: {
        text: {
          [EN_US]: text,
        },
        userName: {
          [EN_US]: user,
        },
      }
  };
  const createdComment= await createEntry(COMMENT_CONTENT_TYPE, data);
  
  // Publish the comment.
  createdComment.publish();

  if (!card.fields.comments) {
    card.fields.comments = { [EN_US]: []};
  }

  // Associate the comment with the card.
  card.fields.comments[EN_US] = [...card.fields.comments[EN_US], {
    sys: {
      type: 'Link',
      linkType: 'Entry',
      id: createdComment.sys.id,
    }
  }];
  
  const updatedCard = await card.update();
  // Publish the change.
  await updatedCard.publish();

  return createdComment;
}