import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Icon from './Icon.js';

const useStyles = makeStyles(theme => ({
  deckSection: {
    paddingBottom: '40px',
    '&:last-child': {
      marginBottom: '20px',
    },
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    scrollSnapType: 'x proximity',
  },
  deckItem: {
    scrollSnapAlign: 'start',
    cursor: 'pointer',
  },
  deckItemLink: {
    display: 'block',
    textDecoration: 'none',
    color: '#333',
  },
  deckCover: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    position: 'relative',

    '&:after': {
      content: '""',
      display: 'block',
      background: 'rgba(0, 0, 0, 0.5)',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      position: 'absolute',
      borderRadius: theme.shape.borderRadius,
      opacity: 0,
      transition: 'opacity 0.2s'
    },

    // '&:hover:after': {
    //   opacity: 0.8,
    // },
  },
  deckIcon: {
    width: 'auto',
    height: 'auto',
    flex: 1,
    opacity: 0.75,
    padding: '20%',
  },
  inkdocCover: {
    height: '80%',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  deckName: {
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: 700,
    padding: '8px 0',
    textAlign: 'center',
    color: '#495285'

    // '&:hover': {
    //   textDecoration: 'underline',
    // },
  },
  deckSectionTitle: {
    fontSize: '16px',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: '#495285',
    letterSpacing: 0.5
  }
}));

const FAKE_INKDOCS = [
  {
    title: 'Inspections',
    image: '/manual.png',
  },
  {
    title: 'To-Go Orders',
    image: '/bag.png',
  },
  {
    title: 'Recipes',
    image: '/burger.png',
  },
  {
    title: 'Safety Gear',
    image: '/safety-gear.png',
  },
  {
    title: 'Food Handling',
    image: '/food-handling.png',
  },
];

function DeckSection(props) {
  const classes = useStyles();

  return (
    <div className={classes.deckSection}>
      <Typography className={classes.deckSectionTitle} gutterBottom>
        {props.title}
      </Typography>
      <GridList
        cols={2.5}
        className={classes.gridList}
        spacing={15}
        style={{
          // This is to counteract the 16px of padding that the Container element adds, so that
          // the carousel goes all the way to the edge of the screen.
          marginLeft: '-16px',
          marginRight: '-16px',
        }}
      >
        {props.children}
      </GridList>
    </div>
  );
}

export default function DeckList(props) {
  const classes = useStyles();

  return (
    <Fragment>
      <DeckSection title="My Library">
        {FAKE_INKDOCS.map(fakeInkdoc => (
          <GridListTile
            key={fakeInkdoc.title}
            className={classes.deckItem}
          >
            <img src={fakeInkdoc.image} alt={fakeInkdoc.title} className={classes.inkdocCover} />
            <div className={classes.deckName}>{fakeInkdoc.title}</div>
          </GridListTile>
        ))}
      </DeckSection>
      <DeckSection title="Team Topics">
        {props.decks.map(deck => (
          <GridListTile
            key={deck.sys.id}
            className={classes.deckItem}
          >
            <Link to={`/${deck.sys.id}`} className={classes.deckItemLink}>
              <div
                className={classes.deckCover}
                style={{ backgroundColor: deck.fields.coverColor }}
               >
                <Icon
                  name={deck.fields.coverIcon}
                  className={classes.deckIcon}
                  style={{ color: '#fff' }}
                />
              </div>
              <div className={classes.deckName}>{deck.fields.name}</div>
             </Link>
          </GridListTile>
        ))}
      </DeckSection>
    </Fragment>
  );
}
