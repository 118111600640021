import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CommentableCard from './CommentableCard'

const useStyles = makeStyles(theme => ({
  deck: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 10px',
  },
}));

export default function DeckDetail(props) {
  const classes = useStyles();

  const { cards = [] } = props.deck.fields;
  const reversableCards = [...cards];
  reversableCards.reverse();

  return (
    <div className={classes.deck}>
      {reversableCards.map(card => (
        <CommentableCard key={card.sys.id} card={card} setShouldFetch={props.setShouldFetch} />
      ))}
    </div>
  );
}
