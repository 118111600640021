import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';

import Comments from './Comments';
import Icon from './Icon.js';
import { postComment } from './data-access';

const IMAGE_WIDTH = 450;

const DATE_STRING_FORMAT = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
}

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: '100%',
    marginTop: '15px',
    boxShadow: '0px 10px 20px rgba(0,0,0,0.2)'
  },
  media: {},
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function getIconName(type) {
  let name;
  switch(type) {
    case 'video':
      name = 'VideoLabel';
      break;
    case 'text':
      name = 'TextFields';
      break;
    case 'image':
      name = 'Image';
      break;
    default:
      name = 'ErrorOutline';
  }

  return <Icon name={name} />;
}

function getMediaTypeComponent(type) {
  let component;
  switch(type) {
    case 'video':
      component = 'video';
      break;
    case 'image':
      component = 'img';
      break;
    default:
      throw Error('Unsupported media type.');
  }

  return component;
}

function getMediaTypeSource(card) {
  switch(card.fields.type) {
    case 'video':
      return card.fields.video.fields.file.url
    case 'image':
      return `${card.fields.images[0].fields.file.url}?fit=scale&w=${IMAGE_WIDTH}`;
    default:
      return 'not found';
  }
}

  export default function CommentableCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  const { card } = props;
  if (!card.fields) return null;

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={getIconName(card.fields.type)}
        action={
        <IconButton aria-label="settings">
          <Icon name="MoreVert" />
        </IconButton>
        }
        title={card.fields.desc}
        subheader={new Date(card.sys.createdAt).toLocaleDateString('en-US', DATE_STRING_FORMAT)}
      />
      {
        card.fields.type !== 'text' ?
          <CardMedia
            className={classes.media}
            component={getMediaTypeComponent(card.fields.type)}
            src={getMediaTypeSource(card)}
            controls
          /> : null
      }
      <CardContent>
        {card.fields.textContent}
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="favorite">
          <Icon name="Favorite" />
        </IconButton>
        <IconButton aria-label="share">
          <Icon name="Share" />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="comments"
        >
          <Icon name="ExpandMore" />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Comments
          comments={card.fields.comments}
          key={card.sys.updatedAt}
          postComment={(comment) => postComment(card.sys.id, comment).then(() => props.setShouldFetch(true))}
        />
      </Collapse>
    </Card>
  );
  }
