import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import Icon from './Icon.js';

const useStyles = makeStyles({
  root: {
    width: '100%',
    boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.1)',
    height: '72px',
    paddingBottom: '18px',
    zIndex: 2000
  },
});

export default function NavBottom() {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  return(
    <BottomNavigation
      showLabels
      value={value}
      onChange={(evt, value) => setValue(value)}
      className={classes.root}
    >
      <BottomNavigationAction
        label="Library"
        icon={<Icon name='CollectionsBookmark' />}
        onClick={() => window.location.reload()}
      />
      <BottomNavigationAction label="Training" icon={<Icon name="CheckCircleOutline" />} />
      <BottomNavigationAction label="Profile" icon={<Icon name="AccountCircle" />} />
    </BottomNavigation>
  );
}
