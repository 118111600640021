import * as Contentful from 'contentful';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';

import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import Slide from '@material-ui/core/Slide';

import CreateContainer from './Creation/CreateContainer.js';
import DeckDetail from './DeckDetail.js';
import DeckList from './DeckList.js';
import NavTop from './NavTop.js';
import NavBottom from './NavBottom.js';
import Icon from './Icon.js';

import Leaf from './img/bg-leaf.svg';

/**
 * Contentful 'quick-create-service' token
 * https://app.contentful.com/spaces/kqtvjeiyddqx/api/keys/4Ii6yOBFDKPD6qAIdJlUdg
 */
const client = Contentful.createClient({
  space: 'kqtvjeiyddqx',
  accessToken: 'k49fBVbE9FcUmtjZGo_O2PRG-cKcDsSpMapY3xril4Q'
});

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#fff"
    }
  }
});

const useStyles = makeStyles(theme => ({
  app: {
    height: '100%',
    display: 'flex',
    'flex-direction': 'column',
    backgroundColor: '#fff',
    backgroundImage: `url(${Leaf})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom -40px left -100px'
  },
  scrollContainer: {
    paddingTop: '24px',
    flex: 1,
    overflowY: 'auto',
    scrollBehavior: 'smooth',
  },
  scrollContainerWithoutSpacing: {
    flex: 1,
    overflowY: 'auto',
    scrollBehavior: 'smooth',
    padding: 0,
    margin: 0,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
    zIndex: 10
  },
}));

// Transition for the Creation dialog. Implementation copied from
// https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/dialogs/FullScreenDialog.js#L26.
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function App() {
  const [decks, setDecks] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    if (shouldFetch) {
      (async () => {
        const response = await client.getEntries({ content_type: 'deck', include:'2' });
        setDecks(response.items);
        setShouldFetch(false);
      })();
    }
  }, [shouldFetch]);

  return (
    <div className={classes.app}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
      </MuiThemeProvider>

      <Switch>
        <Route
          path="/:deckId"
          render={routeProps => {
            const deckId = routeProps.match.params.deckId;
            const deck = decks.find(deck => deck.sys.id === deckId);
            if (!decks.length) {
              return 'Loading…';
            } else if (!deck) {
              return (
                <div>
                  <div>Unable to find deck {deckId}</div>
                  <Link to="/">Take me back</Link>
                </div>
              );
            }

            return (
              <Fragment>
                <Container maxWidth="sm" className={classes.scrollContainerWithoutSpacing}>
                  <NavTop
                    title={deck.fields.name}
                    buttonLeft='back'
                    buttonRight='search'
                    variant='detail'
                    backgroundColor={deck.fields.coverColor}
                  />
                  <DeckDetail deck={deck} setShouldFetch={setShouldFetch}/>
                </Container>

              </Fragment>
            );
          }}
        />
        <Route
          path="/"
          exact
          render={() => (
            <Fragment>
              <NavTop title='Library' buttonRight='search-dark'/>
              <Container maxWidth="sm" className={classes.scrollContainer}>
                <DeckList decks={decks} />
              </Container>
              <Fab
                aria-label='Create'
                className={classes.fab}
                color='primary'
                onClick={() => setIsCreating(true)}
              >
                <Icon
                  name='Add'
                  fontSize='large'
                />
              </Fab>
            </Fragment>
          )}
        />
        <Route path="/" render={() => <Redirect to="/" />} />
      </Switch>
      <Dialog
        fullScreen
        open={isCreating}
        onClose={() => setIsCreating(false)}
        TransitionComponent={Transition}
      >
        <CreateContainer
          stopCreating={() => setIsCreating(false)}
          decks={decks}
          setShouldFetch={setShouldFetch}
        />
      </Dialog>
      <NavBottom />
    </div>
  );
}
