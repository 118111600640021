import React, { useState } from 'react';

import MakeDat from './MakeDat.js';
import WhatUMaking from './WhatUMaking.js';

export default function CreateContainer({ stopCreating, decks, setShouldFetch }) {
  // The type of content we're creating.
  const [contentType, setContentType] = useState(null);
  // For passing the file information between WhatUMaking and MakeDat.
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [imageBuffer, setImageBuffer] = useState(null);
  const [videoBuffer, setVideoBuffer] = useState(null);

  if (contentType) {
    return <MakeDat
              decks={decks}
              contentType={contentType}
              imageFile={imageFile}
              videoFile={videoFile}
              imageBuffer={imageBuffer}
              videoBuffer={videoBuffer}
              setShouldFetch={setShouldFetch}
              stopCreating={stopCreating}
            />;
  }

  return (
    <WhatUMaking
      setContentType={setContentType}
      setImageFile={setImageFile}
      setVideoFile={setVideoFile}
      setImageBuffer={setImageBuffer}
      setVideoBuffer={setVideoBuffer}
      stopCreating={stopCreating}
    />
  );
}