import React from 'react'
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Icon from './Icon.js';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'transparent',
    boxShadow: 'none',
    // borderBottom: '1px solid lightgray',
  },
  detailBar: {
    paddingLeft: '52px',
  },
}));

function renderButton(button, stopCreating) {
  switch(button) {
    case 'close': 
        return <IconButton onClick={stopCreating} edge="start" color="inherit" aria-label="menu">
            <Icon name='Close' />
          </IconButton>
    case 'search': 
        return <IconButton edge="start" style={{ color: '#fff', position: 'absolute', right: 0 }} color="inherit" aria-label="menu">
            <Icon name='Search' />
          </IconButton>
    case 'search-dark': 
        return <IconButton edge="start" style={{ color: '#3F51B5', position: 'absolute', right: 0 }} color="inherit" aria-label="menu">
            <Icon name='Search' />
          </IconButton>
    case 'back':
      return (
        <Link to="/">
          <IconButton edge="start" style={{ color: '#fff' }} aria-label="menu">
            <Icon name='ArrowBack' />
          </IconButton>
        </Link>
      );
    default:
      return (
        <Link to="/">
          <IconButton edge="start" color="inherit" aria-label="menu">
            <Icon name='Menu' />
          </IconButton>
        </Link>
      );
  }
}

export default function NavTop(props) {
  const classes = useStyles();

  return (
    <AppBar
      className={classes.root}
      position="static"
      color="default"
      style={{ backgroundColor: props.backgroundColor }}
    >
      <Toolbar>
        {renderButton(props.buttonLeft, props.stopCreating)}
        <Typography variant="h6" color="inherit">
          {!props.variant && props.title}
        </Typography>
        {props.buttonRight && renderButton(props.buttonRight)}
      </Toolbar>
      {props.variant && (
        <Toolbar className={classes.detailBar}>
          <Typography variant="h6" style={{color: '#fff'}}>
            {props.title}
          </Typography>
        </Toolbar>
      )}
    </AppBar>
  );
}
